/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

export const productSlice = createSlice({
    name: 'productSlice',
    initialState: {
        id: 0,
        codice: '',
        nome: '',
        tipoProdotto: 0,
        unitaMisura: 0,
        unitaUtilizzo: 0,
        setAttributi: 0,
        categorie: [],
        aliquotaIva: 0,
        prezzoVendita: '',
        costo: '',
        descrizioneBreve: '',
        note: '',
        statoServizio: '',
        attivo: false,
        visibile: false,
        disponibile: false,
        inEvidenza: false,
        ean: '',
        posizioneMagazzino: '',
        capacitaErogativa: '',
        tempoErogazione: 0
    },
    reducers: {
        SET_PRODUCT_DUPLICATE: (state, action) => {
            state.id = 0
            state.nome = action.payload.nome,
                state.tipoProdotto = action.payload.tipoProdotto,
                state.unitaMisura = action.payload.unitaMisura,
                state.unitaUtilizzo = action.payload.unitaUtilizzo,
                state.setAttributi = action.payload.setAttributi,
                state.categorie = action.payload.categorie,
                state.aliquotaIva = action.payload.aliquotaIva,
                state.prezzoVendita = action.payload.prezzoVendita,
                state.costo = action.payload.costo,
                state.descrizioneBreve = action.payload.descrizioneBreve,
                state.note = action.payload.note,
                state.statoServizio = action.payload.statoServizio,
                state.attivo = action.payload.attivo,
                state.visibile = action.payload.visibile,
                state.disponibile = action.payload.disponibile,
                state.inEvidenza = action.payload.inEvidenza,
                state.ean = action.payload.ean,
                state.posizioneMagazzino = action.payload.posizioneMagazzino,
                state.capacitaErogativa = action.payload.capacitaErogativa,
                state.tempoErogazione = action.payload.tempoErogazione
        },
        UNSET_PRODUCT_DUPLICATE: (state) => {
            state.id = 0
            state.nome = '',
                state.tipoProdotto = 0,
                state.unitaMisura = 0,
                state.unitaUtilizzo = 0,
                state.setAttributi = 0,
                state.categorie = [],
                state.aliquotaIva = 0,
                state.prezzoVendita = '',
                state.costo = '',
                state.descrizioneBreve = '',
                state.note = '',
                state.statoServizio = '',
                state.attivo = false,
                state.visibile = false,
                state.disponibile = false,
                state.inEvidenza = false,
                state.ean = '',
                state.posizioneMagazzino = '',
                state.capacitaErogativa = '',
                state.tempoErogazione = 0
        }
    }
})

export const {
    SET_PRODUCT_DUPLICATE
} = productSlice.actions

export default productSlice.reducer