// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import booleanVar from './booleanVar'
import docFiscale from './docFiscale'
import duplicateProduct from './duplicateProduct'
const rootReducer = {
  auth,
  navbar,
  layout,
  booleanVar,
  duplicateProduct,
  docFiscale
}

export default rootReducer
